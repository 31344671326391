<template>
  <div class="main">
    <div class="list-tool-bar">
      <div class="action">{{ t('routes.userinfo') }}</div>
      <div class="search"><el-button type="primary" @click="modify">{{ t('table.modify') }}</el-button></div>
    </div>
    <!-- 详情 -->
    <custem-detail
      :loading="detailLoading"
      :descriptions="descriptions"
      class="margin-t4" />
    <!-- 编辑弹框 -->
    <el-dialog
      v-model="winShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="t('user.edit_userinfo')"
      @close="onClose"
      width="900px">
      <create-user
        ref="formRef"
        :addType="addType"
        :winShow="winShow"
        @getList="getUserInfo"
        @onClose="onClose" />
    </el-dialog>
  </div>
</template>
<script setup>
import { inject, ref, reactive, onMounted, computed, nextTick } from 'vue'
import { Personal } from '@/utils/api'
import store from '@/store'
const t = inject('t')
const detailLoading = ref(false)
const winShow = ref(false)
const formRef = ref()
const addType = computed(() => {
  const obj = {
    1: 'SuperAdmin',
    2: 'GeneralAdmin',
    3: 'SalesMan',
    4: 'Enterprise',
    5: 'Subordinate'
  }
  return obj[Number(store.state.users.role_id)]
})
const descriptions = reactive([
  {
    prop: 'name',
    label: t('user.username')
  },
  {
    prop: 'nick_name',
    label: t('user.nick_name')
  },
  {
    prop: 'liaison_man',
    label: t('user.liaison_name')
  },
  {
    prop: 'liaison_phone',
    label: t('user.liaison_phone')
  },
  {
    prop: 'liaison_mail',
    label: t('user.liaison_mail')
  }
])
// 获取用户信息
const getUserInfo = async () => {
  detailLoading.value = true
  try {
    const res = await Personal.UserDetail(store.state.users.id)
    descriptions.forEach(item => {
      item.value = res.params[item.prop] || res[item.prop] || '-' 
    })
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}
// 编辑用户信息
const modify = async () => {
  winShow.value = true
  await nextTick()
  formRef.value.getUserDetail(store.state.users.id)
}
const onClose = () => {
  winShow.value = false
}
onMounted(() => {
  getUserInfo()
})
</script>